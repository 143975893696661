<template>
  <div fixed="top">
    <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <!-- <b-modal
      v-model="openModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="master-project"
      title="Google Account Details"
    >
      <div className="profile-container">
        <div className="container">
          <div className="profile-info">
            <div className="profile-avatar">
              <img
                :src="currentUser.imageUrl"
                :alt="currentUser.name"
                v-if="currentUser.imageUrl"
              />

              <div className="text-avatar" v-else>
                <span>{{ currentUser.name && currentUser.name[0] }}</span>
              </div>
            </div>
            <div className="profile-name">
              <h2>{{ currentUser.name }}</h2>
              <p className="profile-email">{{ currentUser.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import URL_UTILITY from '../../../utility/url.utility'; 
export default {
  name: 'authRedirect',
  data() {
    return {
      loader: true,
      token: null,
      openModal: true,
      currentUser: null,
      userName: null,
      userDtl: null
    };
  },
  mounted() {
    this.OAuth2RedirectHandler();
  },
  methods: {
    OAuth2RedirectHandler() {
      if (this.$route.query.token) {
        this.token = this.$route.query.token;
        // this.getCurrentUser(this.token).then(response => {
        //   this.currentUser = response;
        // });
        this.getResponsibility(this.token)
          .then(response => {
            sessionStorage.setItem('token', this.token);
            const responsibilitys = JSON.stringify(
              response.data.responsibilitys
            );
            localStorage.setItem('responsibilities', responsibilitys);
            // localStorage.setItem('token', token);
            // localStorage.setItem(
            //   'notification',
            //   JSON.stringify(response.data.data.notification_status)
            // );
            // this.loader = false;
            // this.isAuthenticated = true;
            this.getUsersDetails();
            // this.showHideLoginFrom();
            // this.getNotificationCount();
            // this.$router.push('/dashboard');
            window.location = '/dashboard'
            // this.currentUser = response;
          })
          .catch(err => {
            alert(err.message);
          })
          .finally(() => {
            this.loader = false;
          });
      } else if (this.$route.query.error) {
        this.loader = false;
        alert(this.$route.query.error);
      }
    },
    getUsersDetails() {
      if (sessionStorage.token) {
        this.$store.dispatch('auth/getUserDetail').then(response => {
          if (response.status === 200) {
            this.userName = response.data.data.user_name;
            this.userDtl = response.data.data;
            this.$store.dispatch('auth/setUserId', response.data.data.user_id);
            this.$store.dispatch(
              'auth/setUserName',
              response.data.data.user_name
            );
            this.$store.dispatch('auth/setUserDtl', response.data.data);
          }
        });
      }
    },
    getResponsibility(token) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        method: 'GET'
      });
      const options = {
        headers: headers,
        method: 'GET'
      };
      const url = URL_UTILITY.responsibility;
      return fetch(url, options).then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
      );
    },
    getCurrentUser(token) {
      const headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        method: 'GET'
      });
      const options = {
        headers: headers,
        method: 'GET'
      };
      const url = 'https://realapp.in:8088/user/me';
      return fetch(url, options).then(response =>
        response.json().then(json => {
          if (!response.ok) {
            return Promise.reject(json);
          }
          return json;
        })
      );
    }
  }
};
</script>

<style>
.profile-container {
  padding-top: 30px;
}

.profile-info {
  text-align: center;
}

.profile-info .profile-avatar img {
  border-radius: 50%;
  max-width: 250px;
}

.profile-info .profile-name {
  font-weight: 500;
  font-size: 18px;
}

.profile-info .profile-email {
  font-weight: 400;
}

.text-avatar {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  background: #46b5e5;
  background: linear-gradient(45deg, #46b5e5 1%, #1e88e5 64%, #40baf5 97%);
  background-image: -ms-linear-gradient(
    45deg,
    #46b5e5 1%,
    #1e88e5 64%,
    #40baf5 97%
  );
  background-image: -moz-linear-gradient(
    45deg,
    #46b5e5 1%,
    #1e88e5 64%,
    #40baf5 97%
  );
  background-image: -o-linear-gradient(
    45deg,
    #46b5e5 1%,
    #1e88e5 64%,
    #40baf5 97%
  );
  background-image: -webkit-linear-gradient(
    45deg,
    #46b5e5 1%,
    #1e88e5 64%,
    #40baf5 97%
  );
  background-image: linear-gradient(
    45deg,
    #46b5e5 1%,
    #1e88e5 64%,
    #40baf5 97%
  );
}

.text-avatar span {
  line-height: 200px;
  color: #fff;
  font-size: 3em;
}
</style>
